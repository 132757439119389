// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-reference-index-js": () => import("./../../../src/pages/api-reference/index.js" /* webpackChunkName: "component---src-pages-api-reference-index-js" */),
  "component---src-pages-authentication-index-js": () => import("./../../../src/pages/authentication/index.js" /* webpackChunkName: "component---src-pages-authentication-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-concepts-index-js": () => import("./../../../src/pages/key-concepts/index.js" /* webpackChunkName: "component---src-pages-key-concepts-index-js" */)
}

