import React from "react"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap-reboot.min.css"
import "bootstrap/dist/css/bootstrap-grid.min.css"

import "./src/theme/base.less"

export const wrapRootElement = ({ element }) => <>{element}</>

wrapRootElement.propTypes = {
  element: PropTypes.isRequired,
}
